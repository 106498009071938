@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.about-us-container {
  position: relative;
  padding: 40px;
  padding-top: 95px;
  max-width: 800px;
  margin: auto;
  color: white;
}

.about-us-overlay {
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Black overlay with 70% opacity */
  z-index: -1;
}

.about-us-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/ReStringNocturne25_edit.webp'); /* Adjust the path to your image */
  background-size: cover;
  background-position: center;
  z-index: -1;
  animation: fadeIn 0.6s ease-in-out;
}



h1, h2 {
  color: #fff;
}

p {
  color: #fff;
  line-height: 1.6;
}

h1 {
  margin-bottom: 20px;
}

h2 {
  margin-top: 30px;
  margin-bottom: 10px;
}
